import React from "react";
import { getEnvironment } from "./Environment";

const Header = ({ title }) => {
  const env = getEnvironment();
  const envTitle = env.version;
  const showTitle = envTitle.charAt(0).toUpperCase() + envTitle.slice(1);

  const h1Title = title ? title : showTitle;
  return (
    <header>
      <h1>{h1Title}</h1>
    </header>
  );
};

export default Header;
