import React, { useState } from "react";
import BocomboFormMeta from "./BocomboFormMeta";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ModalStyle } from "./Style";

const BocomboMeta = ({ metadata, addMeta, editMeta, deleteMeta }) => {
  const initValues = {
    k: "",
    v: "",
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [value, setValue] = useState("");
  const [key, setKey] = useState("");

  const handleEdit = (e) => {
    e.stopPropagation();
    setKey(e.target.dataset.key);
    setValue(e.target.dataset.value);
    setShowEditModal(true);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setKey(e.target.dataset.key);
    setShowDeleteModal(true);
  };

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteCall = () => {
    deleteMeta(key);
    setShowDeleteModal(false);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const handleEditCall = () => {
    editMeta(key, value);
    setShowEditModal(false);
  };
  const handleChange = (e) => {
    setValue(e.currentTarget.value);
  };

  return (
    <>
      <Modal
        open={showEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Metaschlüssel "{key}"
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ paddingBottom: `20px` }}>
              <TextField value={value} type="text" onChange={handleChange} />
            </div>
            <Button variant="contained" onClick={handleEditCall}>
              Speichern
            </Button>
            <Button variant="outline" onClick={handleEditClose}>
              Abbrechen
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={showDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Metaschlüssel entfernen?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ paddingBottom: `20px` }}>
              Soll der Schlüssel "{key}" entfernt werden?
            </div>
            <Button variant="contained" onClick={handleDeleteCall}>
              Ja
            </Button>
            <Button variant="outline" onClick={handleDeleteClose}>
              Nein
            </Button>
          </Typography>
        </Box>
      </Modal>
      <div className="listOuter">
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Metaschüssel</TableCell>
                <TableCell align="left">Wert</TableCell>
                <TableCell align="right">Optionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metadata.map((row) => {
                return (
                  <TableRow key={row.key}>
                    <TableCell component="th" scope="row">
                      {row.key}
                    </TableCell>
                    <TableCell align="left">{row.value}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        onClick={handleEdit}
                        data-key={row.key}
                        data-value={row.value}
                      >
                        <FontAwesomeIcon icon="edit" /> Editieren
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleDelete}
                        data-key={row.key}
                      >
                        <FontAwesomeIcon icon="trash-alt" /> Löschen
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}

              <BocomboFormMeta
                addMeta={addMeta}
                editMeta={editMeta}
                initValues={initValues}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default BocomboMeta;
