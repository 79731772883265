import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

const BocomboSort = ({ sortingOrder, setSortingOrder }) => {
  const options = [
    {
      label: "A-Z Thema",
      value: "topic_asc",
    },
    {
      label: "Z-A Thema",
      value: "topic_desc",
    },
    {
      label: "A-Z Info",
      value: "name_asc",
    },
    {
      label: "Z-A Info",
      value: "name_desc",
    },
    {
      label: "A-Z Erklärung",
      value: "explanation_asc",
    },
    {
      label: "Z-A Erklärung",
      value: "explanation_desc",
    },
    {
      label: "Neueste zuerst",
      value: "ts_desc",
    },
    {
      label: "Älteste zuerst",
      value: "ts_asc",
    },
  ];
  const handleChange = (e) => {
    setSortingOrder(e.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Select
        labelId="soringorder"
        id="soringorder"
        value={sortingOrder}
        label="Sortierung"
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={"sorting-" + option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default BocomboSort;
