import React, { useState } from "react";
import "./App.css";
import Header from "./Header";
import { GetLoginStatus, LoadData, LoadGroups } from "./Api";
import {
  addGroup,
  renameTasklist,
  deleteTasklist,
  addTasklist,
  duplicateGroup,
  renameGroup,
  getVersion,
  loginApi,
  fetchLogout,
  fetchStatus,
} from "./ApiFetch";
import BocomboTodolist from "./BocomboTodolist";
import BocomboSpinner from "./BocomboSpinner";
import BocomboTodoGrouplist from "./BocomboTodoGrouplist";
import BocomboEditList from "./BocomboEditList";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import { getEnvironment } from "./Environment";
import BocomboList from "./BocomboList";
import BocomboLogin from "./BocomboLogin";
const hash = require(`object-hash`);

function App() {
  const [sessionId, setSessionId] = useState("");
  const [lastTs, setLastTs] = useState(0);
  const [listId, setListId] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [showError, setShowError] = useState(false);

  let loginStatus = GetLoginStatus(lastTs, sessionId);
  let taskData = LoadData(listId, lastTs, sessionId);
  let groupData = LoadGroups(lastTs, sessionId);

  let currentGroup =
    groupId > 0
      ? groupData.filter((group) => {
          return group[0].group_id === groupId;
        })
      : undefined;

  if (typeof currentGroup !== "undefined") {
    currentGroup = currentGroup[0];
  }

  const handleBack = () => {
    setListId(0);
    setGroupId(0);
  };

  const handleBackGroup = () => {
    setListId(0);
  };

  const handleLogout = () => {
    fetchLogout(sessionId).then((response) => {
      setSessionId("");
      setLastTs(lastTs + 1);
    });
  };

  const handleGroupRename = (groupId, description) => {
    setShowError(false);
    renameGroup(groupId, description)
      .then((response) => {
        if (typeof response === "undefined") {
          setShowError(true);
        } else if (response.error) {
          setShowError(true);
        } else {
          let newGroupId = response.data.group_id;
          setLastTs(lastTs + 1);
          setGroupId(newGroupId);
        }
      })
      .catch(() => {
        setShowError(false);
      });
  };

  const handleGroupDuplicate = (groupId, description) => {
    setShowError(false);
    duplicateGroup(groupId, description)
      .then((response) => {
        if (typeof response === "undefined") {
          setShowError(true);
        } else if (response.error) {
          setShowError(true);
        } else {
          let newGroupId = response.data.group_id;
          setLastTs(lastTs + 1);
          setGroupId(newGroupId);
        }
      })
      .catch(() => {
        setShowError(false);
      });
  };

  const handleAddGroup = (groupDescription) => {
    setShowError(false);
    addGroup(groupDescription)
      .then((response) => {
        if (typeof response === "undefined") {
          setShowError(true);
        } else if (response.error) {
          setShowError(true);
        } else {
          let newGroupId = response.data.group_id;
          setLastTs(lastTs + 1);
          setGroupId(newGroupId);
        }
      })
      .catch(() => {
        setShowError(false);
      });
  };

  const handleGroupPdf = (groupId, type, meta) => {
    let environment = getEnvironment();
    let apiUrl =
      environment.apiUrl +
      "?version=" +
      getVersion() +
      "&action=pdf&id=" +
      groupId +
      "&type=" +
      type +
      "&meta=" +
      meta;
    window.location = apiUrl;
  };

  const login = (username, password) => {
    var crypto = require("crypto");
    var shasum = crypto.createHash("sha1");
    shasum.update(password);

    let hash = shasum.digest("hex");

    setShowError(false);
    loginApi(username, hash).then((response) => {
      if (typeof response === "undefined") {
        setShowError(true);
      } else if (response.error) {
        setShowError(true);
      } else {
        setSessionId(response.data.sessionId);
        setLastTs(lastTs + 1);

        let environment = getEnvironment();
        window.location = environment.redirectUrl;
      }
    });
  };

  const handleListRename = (description) => {
    setShowError(false);
    renameTasklist(taskData.id, description).then((response) => {
      if (typeof response === "undefined") {
        setShowError(true);
      } else if (response.error) {
        setShowError(true);
      } else {
        setLastTs(lastTs + 1);
        setListId(taskData.id);
      }
    });
  };

  const handleListDelete = () => {
    setShowError(false);
    deleteTasklist(taskData.id).then((response) => {
      if (typeof response === "undefined") {
        setShowError(true);
      } else if (response.error) {
        setShowError(true);
      } else {
        setLastTs(lastTs + 1);
        setListId(taskData.id);
      }
    });
  };

  const handleListAdd = (id) => {
    setShowError(false);
    addTasklist(id).then((response) => {
      if (typeof response === "undefined") {
        setShowError(true);
      } else if (response.error) {
        setShowError(true);
      } else {
        setLastTs(lastTs + 1);
        // setListId(response.data.list_id);
      }
    });
  };

  const handleRoot = () => {
    window.location.href = "/";
  };

  let groupSignature = hash({ data: groupData });

  /*
  console.log("Group-ID:  " + groupId);
  console.log("List-ID:   " + listId);
  console.log("Signature: " + groupSignature);
  console.log("Task: ");
  console.log(taskData);
*/

  if (typeof loginStatus === "undefined") {
    return (
      <div className="App">
        <Header />
        <p>Prüfe Authentifizierung...</p>
        <BocomboSpinner />
      </div>
    );
  } else if (!loginStatus.auth) {
    return (
      <div className="App">
        <Header />
        <h2>Bitte einloggen</h2>

        {showError && (
          <div>
            <Alert severity="error">Nutzer nicht gefunden</Alert>
          </div>
        )}

        <BocomboLogin action={login} />
      </div>
    );
  } else if (
    typeof taskData == "undefined" ||
    typeof groupData == "undefined"
  ) {
    return (
      <div className="App">
        <Header />
        <BocomboSpinner />
      </div>
    );
  } else if (!taskData.id) {
    return (
      <div className="App" key={"app-" + groupId + "-" + listId}>
        <Header />

        {groupId > 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Alle Listen anzeigen
          </Button>
        )}

        {!taskData.id && (
          <BocomboTodoGrouplist
            key={groupSignature}
            groupData={groupData}
            groupId={groupId}
            setListId={setListId}
            setGroupId={setGroupId}
            addGroup={handleAddGroup}
            handleGroupDuplicate={handleGroupDuplicate}
            handleListAdd={handleListAdd}
            handleGroupRename={handleGroupRename}
            handleGroupPdf={handleGroupPdf}
          />
        )}

        {showError && (
          <div>
            <Alert severity="error">Fehler beim Speichern</Alert>
          </div>
        )}

        {/* <Button variant="outlined" onClick={handleRoot}>
          Zurück zur Übersicht
        </Button> */}

        <Button variant="outlined" onClick={handleLogout}>
          Ausloggen
        </Button>
      </div>
    );
  } else if (taskData.id > 0 && typeof currentGroup !== "undefined") {
    return (
      <div className="App" key={"app-" + groupId + "-" + listId + "-" + lastTs}>
        <Header title={currentGroup[0].group_description} />
        <h2>{taskData.description}</h2>
        <BocomboEditList
          taskData={taskData}
          handleListRename={handleListRename}
          handleListDelete={handleListDelete}
        />

        <Button variant="outlined" onClick={handleBackGroup}>
          Unterliste auswählen
        </Button>

        {taskData.id > 0 && <BocomboTodolist data={taskData} />}
        {/* <Button variant="outlined" onClick={handleBack}>
          Alle Listen anzeigen
        </Button> */}

        {showError && (
          <div>
            <Alert severity="error">Fehler beim Speichern</Alert>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="App" key={"app-" + groupId + "-" + listId}>
        <Header />
        <p>Konnte Liste nicht lesen.</p>
      </div>
    );
  }
}

export default App;
