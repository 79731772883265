import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalStyle } from "./Style";
import BocomboGroupForm from "./BocomboGroupForm";
import { LeftAligned } from "./Style";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faPlus);

const BocomboTodoGroupAdd = ({ addGroup }) => {
  const [showModal, setShowModal] = useState(false);

  const handleAddGroup = (groupDescription) => {
    addGroup(groupDescription);
    setShowModal(false);
  };
  const handleAbort = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  let initValues = { description: "" };
  return (
    <>
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Liste hinzufügen
          </Typography>
          <BocomboGroupForm action={handleAddGroup} initValues={initValues} />
          <Button variant="outline" onClick={handleAbort}>
            Abbrechen
          </Button>
        </Box>
      </Modal>
      <div className="listOuter">
        <div className="listInner">
          <div className="group_header" onClick={openModal}>
            <Box sx={LeftAligned}>
              <h2>
                <FontAwesomeIcon icon="plus" style={{ paddingRight: `20px` }} />
                Hinzufügen
              </h2>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default BocomboTodoGroupAdd;
