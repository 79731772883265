import React from "react";
import BocomboTodoGroupAdd from "./BocomboTodoGroupAdd";
import BocomboTodoGrouplistElement from "./BocomboTodoGrouplistElement";

const BocomboTodoGrouplist = ({
  groupData,
  setListId,
  setGroupId,
  groupId,
  addGroup,
  handleGroupDuplicate,
  handleListAdd,
  handleGroupRename,
  handleGroupPdf
}) => {
  let showGroups = groupData.filter((group) => {
    return groupId === 0 || group[0].group_id === groupId;
  });

  return (
    <div className="listOuter">
      <div className="listInner">
        {showGroups.map((group) => {
          return (
            <BocomboTodoGrouplistElement
              groupData={group}
              setListId={setListId}
              setGroupId={setGroupId}
              handleListAdd={handleListAdd}
              handleGroupDuplicate={handleGroupDuplicate}
              handleGroupRename={handleGroupRename}
              handleGroupPdf={handleGroupPdf}
              key={"group-" + group[0].group_id}
            />
          );
        })}

        {groupId === 0 && <BocomboTodoGroupAdd addGroup={addGroup} />}
      </div>
    </div>
  );
};

export default BocomboTodoGrouplist;
