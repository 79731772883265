import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getEnvironment } from "./Environment";

const BocomboForm = ({ addTask, initValues, textAreaWidth }) => {
  const [element, setElement] = useState(initValues.task);
  const [location, setLocation] = useState(initValues.location);
  const [resolution, setResolution] = useState(initValues.resolution);
  const [explanation, setExplanation] = useState(initValues.explanation);
  const [topic, setTopic] = useState(initValues.topic);

  console.log(textAreaWidth);
  const buttonTitle = initValues.task === "" ? "Hinzufügen" : "Speichern";

  const handleChange1 = (e) => {
    setElement(e.currentTarget.value);
  };

  const handleChange2 = (e) => {
    setLocation(e.currentTarget.value);
  };

  const handleChange3 = (e) => {
    setResolution(e.currentTarget.value);
  };

  const handleChange4 = (e) => {
    setExplanation(e.currentTarget.value);
  };

  const handleChange5 = (e) => {
    setTopic(e.currentTarget.value);
  };

  const handleSubmit = () => {
    addTask(element, location, resolution, explanation, topic);
    setElement("");
    setLocation("");
    setResolution("");
    setExplanation("");
    setTopic("");
  };

  const env = getEnvironment();

  let task_box1 =
    env.language && env.language.task_box1
      ? env.language && env.language.task_box1
      : "Element";
  let task_box2 =
    env.language && env.language.task_box2
      ? env.language && env.language.task_box2
      : "Aufbewahrungsort";
  let task_box3 =
    env.language && env.language.task_box3
      ? env.language && env.language.task_box3
      : "Verstauen";
  let task_box4 =
    env.language && env.language.task_box4
      ? env.language && env.language.task_box4
      : "Erklärung";

  let task_box5 =
    env.language && env.language.task_box5
      ? env.language && env.language.task_box5
      : "Thema";

  return (
    <div onSubmit={handleSubmit} style={{ padding: "10px" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: textAreaWidth },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            value={topic}
            type="text"
            onChange={handleChange5}
            placeholder={task_box5}
            multiline
            rows={3}
          />
        </div>
        <div>
          <TextField
            value={explanation}
            type="text"
            onChange={handleChange4}
            placeholder={task_box4}
            multiline
            rows={3}
          />
        </div>
        <div>
          <TextField
            value={element}
            type="text"
            onChange={handleChange1}
            placeholder={task_box1}
            multiline
            rows={3}
          />
        </div>
        <div>
          <TextField
            value={location}
            type="text"
            onChange={handleChange2}
            placeholder={task_box2}
            multiline
            rows={3}
          />
        </div>
        <div>
          <TextField
            value={resolution}
            type="text"
            onChange={handleChange3}
            placeholder={task_box3}
            multiline
            rows={3}
          />
        </div>
        <div>
          <Button variant="contained" onClick={handleSubmit}>
            {buttonTitle}
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default BocomboForm;
