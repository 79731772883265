import React from "react";
import BocomboTask from "./BocomboTask";

const BocomboList = ({
  list,
  handleToggle,
  handleDelete,
  handleFilter,
  editTask,
}) => {
  return (
    <div className="listOuter">
      <div className="listInner">
        {list.map((task) => {
          return (
            <BocomboTask
              task={task}
              handleToggle={handleToggle}
              handleDelete={handleDelete}
              editTask={editTask}
              key={task.id + "task"}
            />
          );
        })}
      </div>

      {/* <button style={{ margin: "20px" }} onClick={handleFilter}>
        Erledigte Tasks löschen
      </button> */}
    </div>
  );
};

export default BocomboList;
