import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalStyle } from "./Style";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BocomboGroupForm from "./BocomboGroupForm";

library.add(faEdit);

const BocomboTodoGroupRename = ({ handleGroupRename, description }) => {
  const [showModal, setShowModal] = useState(false);

  const handleRenameGroup = (title) => {
    handleGroupRename(title);
    setShowModal(false);
  };
  const handleAbort = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  let initValues = { description: description };
  return (
    <>
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Liste benennen
          </Typography>

          <BocomboGroupForm
            action={handleRenameGroup}
            initValues={initValues}
          />

          <Button variant="outline" onClick={handleAbort}>
            Abbrechen
          </Button>
        </Box>
      </Modal>

      <Button variant="outlined" onClick={openModal}>
        <FontAwesomeIcon icon="edit" />
      </Button>
    </>
  );
};

export default BocomboTodoGroupRename;
