import React, { useState } from "react";
import Button from "@mui/material/Button";
import { ModalStyle } from "./Style";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
} from "@mui/material";

library.add(faFilePdf);

const BocomboTodoGroupPdf = ({ handleGroupPdf }) => {
  const [showModal, setShowModal] = useState(false);
  const [checkedMeta, setCheckedMeta] = useState(true);

  const openPdf = (param) => {
    handleGroupPdf(param, checkedMeta ? 1 : 0);
  };

  const handleAbort = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleChangeMeta = () => {
    setCheckedMeta(!checkedMeta);
  };

  return (
    <>
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <h2>Optionen</h2>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={checkedMeta} onChange={handleChangeMeta} />
              }
              label="Metadaten zusammenfassen"
            />
          </FormGroup>

          <h2>Aktionen</h2>
          <Button
            variant="contained"
            onClick={(event) => openPdf(1)}
            style={{ width: `100%`, marginBottom: `12px` }}
          >
            Alle Aufgaben
          </Button>
          <Button
            variant="contained"
            onClick={(event) => openPdf(2)}
            style={{ width: `100%`, marginBottom: `12px` }}
          >
            erledigte Aufgaben markieren
          </Button>
          <Button
            variant="contained"
            onClick={(event) => openPdf(3)}
            style={{ width: `100%`, marginBottom: `12px` }}
          >
            ohne erledigte Aufgaben
          </Button>
          <Button
            variant="outline"
            onClick={handleAbort}
            style={{ width: `100%` }}
          >
            Abbrechen
          </Button>
        </Box>
      </Modal>

      <Button variant="outlined" onClick={openModal}>
        <FontAwesomeIcon icon="file-pdf" />
      </Button>
    </>
  );
};

export default BocomboTodoGroupPdf;
