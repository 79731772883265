export const getEnvironment = () => {
  return {
    apiUrl: "https://protokoll.falkpost.de/list/api.php",
    redirectUrl: "https://protokoll.falkpost.de/list",
    version: "protokoll",
    language: {
      sublist: "1. ",
      task_box1: "Informationen / Ergebnis",
      task_box2: "Verantwortlichen",
      task_box3: "Deadline",
      task_box4: "Erklärung",
      icon0: "info",
      icon1: "user",
      icon2: "clock",
    },
  };
};
