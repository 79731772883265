import React from "react";

const BocomboGroup = ({ group, setGroupId, setListId }) => {
  const handleClick = (e) => {
    e.preventDefault();
    setGroupId(group.group_id);
    setListId(e.currentTarget.id);
  };

  return (
    <div
      className="group"
      name="group"
      id={group.list_id}
      onClick={handleClick}
    >
      <div>{group.list_description}</div>
    </div>
  );
};

export default BocomboGroup;
