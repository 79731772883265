import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
library.add(faSpinner);

const BocomboSpinner = () => {
  return <FontAwesomeIcon icon="spinner" className="spin" />;
};

export default BocomboSpinner;
