import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { getEnvironment } from "./Environment";
import { TableCell, TableRow } from "@mui/material";

const BocomboFormMeta = ({ addMeta, initValues }) => {
  const [k, setK] = useState(initValues.k);
  const [v, setV] = useState(initValues.v);

  const buttonTitle = initValues.k === "" ? "Hinzufügen" : "Speichern";

  const handleChange1 = (e) => {
    setK(e.currentTarget.value);
  };

  const handleChange2 = (e) => {
    setV(e.currentTarget.value);
  };

  const handleSubmit = () => {
    addMeta(k, v);
    setK("");
    setV("");
  };

  const env = getEnvironment();

  let task_box1 =
    env.language && env.language.meta_box1
      ? env.language && env.language.meta_box1
      : "Key";
  let task_box2 =
    env.language && env.language.meta_box2
      ? env.language && env.language.meta_box2
      : "Value";

  return (
    <TableRow>
      <TableCell>
        <TextField
          value={k}
          type="text"
          onChange={handleChange1}
          placeholder={task_box1}
        />
      </TableCell>
      <TableCell>
        <TextField
          value={v}
          type="text"
          onChange={handleChange2}
          placeholder={task_box2}
        />
      </TableCell>
      <TableCell align="right">
        <Button variant="contained" onClick={handleSubmit}>
          {buttonTitle}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default BocomboFormMeta;
