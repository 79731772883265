import { Grid } from "@mui/material";
import React from "react";
import BocomboGroup from "./BocomboGroup";
import BocomboGroupAdd from "./BocomboGroupAdd";
import BocomboTodoGroupDuplicate from "./BocomboTodoGroupDuplicate";
import BocomboTodoGroupRename from "./BocomboTodoGroupRename";
import BocomboTodoGroupPdf from "./BocomboTodoGroupPdf";

const BocomboTodoGrouplistElement = ({
  groupData,
  setListId,
  setGroupId,
  handleListAdd,
  handleGroupDuplicate,
  handleGroupRename,
  handleGroupPdf,
}) => {
  const handleDuplicate = (title) => {
    handleGroupDuplicate(groupData[0].group_id, title);
  };

  const handleRename = (title) => {
    handleGroupRename(groupData[0].group_id, title);
  };

  const handlePdf = (param, meta) => {
    handleGroupPdf(groupData[0].group_id, param, meta);
  };

  return (
    <div className="listOuter">
      <div className="listInner">
        <div className="group_header">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <h2>{groupData[0].group_description}</h2>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: `right`, height: `100%` }}>
                <div className="full-height">
                  <BocomboTodoGroupDuplicate
                    handleGroupDuplicate={handleDuplicate}
                  />

                  <BocomboTodoGroupPdf handleGroupPdf={handlePdf} />

                  <BocomboTodoGroupRename
                    handleGroupRename={handleRename}
                    description={groupData[0].group_description}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        {groupData.map((group) => {
          return (
            <BocomboGroup
              group={group}
              setListId={setListId}
              setGroupId={setGroupId}
              key={"group-" + group.group_id + "-" + group.list_id}
            />
          );
        })}

        <BocomboGroupAdd
          group={groupData[0]}
          handleListAdd={handleListAdd}
          key={"group-" + groupData[0].group_id + "-add"}
        />
      </div>
    </div>
  );
};

export default BocomboTodoGrouplistElement;
