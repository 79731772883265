import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const BocomboGroupForm = ({ action, initValues }) => {
  const [description, setDescription] = useState(initValues.description);

  const buttonTitle = initValues.task === "" ? "Hinzufügen" : "Speichern";

  const handleChange1 = (e) => {
    setDescription(e.currentTarget.value);
  };

  const handleSubmit = () => {
    action(description);
    setDescription("");
  };

  return (
    <div onSubmit={handleSubmit} style={{ padding: "10px" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            value={description}
            type="text"
            onChange={handleChange1}
            placeholder="Listenname"
          />
        </div>
        <div>
          <Button variant="contained" onClick={handleSubmit}>
            {buttonTitle}
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default BocomboGroupForm;
