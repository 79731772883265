import {
  fetchList,
  saveList,
  fetchGroups,
  addGroup,
  fetchStatus,
  fetchLogout,
} from "./ApiFetch";
import { useState, useEffect } from "react";

export const LogOutFromApi = (lastTs, sessionId) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    fetchLogout(sessionId).then((response) => {
      if (typeof response == "undefined") {
      } else {
        setData(response.data);
      }
    });
  }, [lastTs, sessionId]);

  return data;
};

export const GetLoginStatus = (lastTs, sessionId) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    fetchStatus(sessionId).then((response) => {
      if (typeof response == "undefined") {
      } else {
        setData(response.data);
      }
    });
  }, [lastTs, sessionId]);

  return data;
};

export const LoadData = (id, lastTs, sessionId) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    fetchList(id, sessionId).then((response) => {
      if (typeof response == "undefined") {
      } else if (!response.error) {
        setData(response.data);
      }
    });
  }, [id, lastTs, sessionId]);

  return data;
};

export const SaveData = (id, tasks) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    saveList(id, data).then((response) => {
      if (typeof response == "undefined") {
      } else if (!response.error) {
        setData(response.data);
      } else {
      }
    });
  }, [id, tasks]);

  return data;
};

export const LoadGroups = (lastTs, sessionId) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    fetchGroups(sessionId).then((response) => {
      if (typeof response == "undefined") {
        console.log("error loading groups");
      } else if (!response.error) {
        setData(Object.values(response.data));
      }
    });
  }, [lastTs]);

  return data;
};

export const AddGroup = (description) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    addGroup(description).then((response) => {
      if (typeof response == "undefined") {
      } else if (!response.error) {
        setData(response.data);
      } else {
      }
    });
  }, [description]);

  return data;
};
