import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faTrashAlt,
  faLocationArrow,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ModalStyle } from "./Style";
import { TextField } from "@mui/material";

library.add(faEdit);
library.add(faTrashAlt);
library.add(faLocationArrow);
library.add(faBox);

const BocomboEditList = ({ taskData, handleListRename, handleListDelete }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState(
    taskData.description
  );

  let oldValue = taskData.description;

  const handleEditLink = (e) => {
    e.stopPropagation();
    setShowEditModal(true);
  };

  const handleDeleteLink = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };

  const handleChangeDescription = (e) => {
    let newValue = e.currentTarget.value.trim();
    setDisabled(newValue === oldValue || newValue === "");
    setCurrentDescription(e.currentTarget.value);
  };

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const handleDeleteCall = () => {
    handleListDelete();
    setShowDeleteModal(false);
  };

  const handleSaveCall = () => {
    handleListRename(currentDescription);
    setShowEditModal(false);
  };

  return (
    <>
      <Modal
        open={showDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Liste entfernen?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ paddingBottom: `20px` }}>
              Soll die Liste "{taskData.description}" entfernt werden?
            </div>
            <Button variant="contained" onClick={handleDeleteCall}>
              Ja
            </Button>
            <Button variant="outline" onClick={handleDeleteClose}>
              Nein
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={showEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Liste umbenennen
          </Typography>

          <div>
            <TextField
              value={currentDescription}
              type="text"
              onChange={handleChangeDescription}
              placeholder="Element"
            />
          </div>
          <div>
            <Button
              variant="outline"
              onClick={handleSaveCall}
              disabled={disabled}
            >
              OK
            </Button>
            <Button variant="variant" onClick={handleEditClose}>
              Abbrechen
            </Button>
          </div>
        </Box>
      </Modal>

      <div
        id={taskData.id}
        key={taskData.id + "list"}
        name="list"
        value={taskData.id}
      >
        <Button variant="outlined" onClick={handleEditLink}>
          <FontAwesomeIcon icon="edit" /> Editieren
        </Button>
        <Button variant="outlined" onClick={handleDeleteLink}>
          <FontAwesomeIcon icon="trash-alt" /> Löschen
        </Button>
      </div>
    </>
  );
};

export default BocomboEditList;
