import React from "react";

const BocomboGroupAdd = ({ group, handleListAdd }) => {
  const handleClick = (e) => {
    e.preventDefault();
    handleListAdd(group.group_id);
  };

  return (
    <div
      className="group"
      name="group"
      id={group.list_id}
      onClick={handleClick}
    >
      <div>+ Hinzufügen</div>
    </div>
  );
};

export default BocomboGroupAdd;
