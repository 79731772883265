import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const BocomboLogin = ({ action }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChange1 = (e) => {
    setUsername(e.currentTarget.value);
  };
  const handleChange2 = (e) => {
    setPassword(e.currentTarget.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    action(username, password);
  };

  return (
    <div onSubmit={handleSubmit} style={{ padding: "10px" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="on"
      >
        <div>
          <TextField
            value={username}
            type="text"
            onChange={handleChange1}
            placeholder="Benutzername"
          />
          <TextField
            value={password}
            type="password"
            onChange={handleChange2}
            placeholder="Passwort"
          />
        </div>
        <div>
          <Button variant="contained" type="submit">
            Einloggen
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default BocomboLogin;
