export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalStyleFull = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: `70%`,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const SpaceEvenly = {
  display: "flex",
  justifyContent: "space-evenly",
  p: 1,
  m: 1,
};

export const LeftAligned = {
  display: "flex",
  justifyContent: "flex-start",
  p: 1,
  m: 1,
};
