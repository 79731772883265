import { getEnvironment } from "./Environment";

export const getUrl = (sessionId) => {
  let environment = getEnvironment();
  let result = environment.apiUrl + "?version=" + getVersion();

  if (sessionId) {
    // result = result + "&PHPSESSID=" + sessionId;
  }

  return result;
};

export const getVersion = () => {
  let environment = getEnvironment();
  return environment.version;
};

export const fetchLogout = (sessionId) => {
  let url = getUrl(sessionId) + "&action=logout";

  return fetch(url)
    .then((response) => response.json())
    .catch(function (error) {
      console.log(error);
    });
};

export const fetchStatus = (sessionId) => {
  let url = getUrl(sessionId) + "&action=status";

  return fetch(url)
    .then((response) => response.json())
    .catch(function (error) {
      console.log(error);
    });
};

export const fetchList = (id, sessionId) => {
  let url = getUrl(sessionId) + "&action=load&id=" + id;

  return fetch(url)
    .then((response) => response.json())
    .catch(function (error) {
      console.log(error);
    });
};

export const fetchGroups = (sessionId) => {
  let url = getUrl(sessionId) + "&action=loadGroups";

  return fetch(url)
    .then((response) => response.json())
    .catch(function (error) {
      console.log(error);
    });
};

export const saveList = (id, tasks, metadata) => {
  let url = getUrl() + "&action=save&id=" + id;

  return fetch(url, {
    method: "post",
    body: serializeToForm({ tasks: tasks, metadata: metadata }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Speichern nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const loginApi = (username, password) => {
  let url = getUrl() + "&action=login";

  return fetch(url, {
    method: "post",
    body: serializeToForm({ username: username, password: password }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Login nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const renameTasklist = (id, description) => {
  let url = getUrl() + "&action=renameList";

  return fetch(url, {
    method: "post",
    body: serializeToForm({ id: id, listName: description }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Speichern nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteTasklist = (id, description) => {
  let url = getUrl() + "&action=deleteList";

  return fetch(url, {
    method: "post",
    body: serializeToForm({ id: id }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Speichern nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const addTasklist = (id) => {
  let url = getUrl() + "&action=addList";

  return fetch(url, {
    method: "post",
    body: serializeToForm({ id: id }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Speichern nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const addGroup = (groupDescription) => {
  let env = getEnvironment();
  let url = getUrl() + "&action=addGroup";
  if (env.language.sublist) {
    url = url + "&listName=" + env.language.sublist;
  }

  return fetch(url, {
    method: "post",
    body: serializeToForm({ groupName: groupDescription }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Speichern nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const renameGroup = (id, description) => {
  let url = getUrl() + "&action=renameGroup";

  return fetch(url, {
    method: "post",
    body: serializeToForm({ id: id, groupName: description }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Speichern nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const duplicateGroup = (id, description) => {
  let url = getUrl() + "&action=duplicateGroup";

  return fetch(url, {
    method: "post",
    body: serializeToForm({ id: id, groupName: description }),
  })
    .then((response) => response.text())
    .then((response) => {
      try {
        return JSON.parse(response);
      } catch (err) {
        return {
          error: 1,
          data: "Speichern nicht erfolgreich",
        };
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const serializeToForm = (obj, prefix, formdata) => {
  if (typeof formdata === "undefined") {
    formdata = new FormData();
  }

  let p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];

      if (v !== null && typeof v === "object") {
        serializeToForm(v, k, formdata);
      } else {
        formdata.append(k, v);
      }
    }
  }

  return formdata;
};
