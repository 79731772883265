import React, { useState } from "react";
import BocomboList from "./BocomboList";
import BocomboForm from "./BocomboForm";
import BocomboSort from "./BocomboSort";
import BocomboSave from "./BocomboSave";
import { v4 as uuidv4 } from "uuid";
import BocomboMeta from "./BocomboMeta";

const sortingOrderElement = ({ a, b, sortingOrder }) => {
  if (sortingOrder === "ts_asc") {
    return a.added_ts > b.added_ts ? 1 : -1;
  } else if (sortingOrder === "ts_desc") {
    return a.added_ts > b.added_ts ? -1 : 1;
  } else if (sortingOrder === "name_asc") {
    return a.task > b.task ? 1 : -1;
  } else if (sortingOrder === "name_desc") {
    return a.task > b.task ? -1 : 1;
  } else if (sortingOrder === "explanation_asc") {
    return a.explanation > b.explanation ? 1 : -1;
  } else if (sortingOrder === "explanation_desc") {
    return a.explanation > b.explanation ? -1 : 1;
  } else if (sortingOrder === "topic_asc") {
    return a.topic > b.topic ? 1 : -1;
  } else if (sortingOrder === "topic_desc") {
    return a.topic > b.topic ? -1 : 1;
  } else {
    return a.added_ts > b.added_ts ? 1 : -1;
  }
};

const BocomboTodolist = ({ data }) => {
  // const title = data.description;
  const id = data.id;

  let defaultSortingOrder = localStorage.getItem("sortingOrder");
  if (!defaultSortingOrder) {
    defaultSortingOrder = "ts_asc";
  }

  const [tasks, setTasks] = useState(data.data);
  const [metadata, setMetadata] = useState(data.meta);
  const [sortingOrder, setSortingOrder] = useState(defaultSortingOrder);
  const [hasChanges, setHasChanges] = useState(false);

  const handleToggle = (id) => {
    let mapped = tasks.map((task) => {
      return task.id === id
        ? { ...task, complete: !task.complete }
        : { ...task };
    });
    setTasks(mapped);
    setHasChanges(true);
  };

  const handleDelete = (id) => {
    let filtered = tasks.filter((task) => {
      return task.id !== id;
    });
    setTasks(filtered);
    setHasChanges(true);
  };

  const handleFilter = () => {
    let filtered = tasks.filter((task) => {
      return !task.complete;
    });
    setTasks(filtered);
    setHasChanges(true);
  };

  const addTask = (userInput, location, resolution, explanation, topic) => {
    let copy = [...tasks];
    copy = [
      ...copy,
      {
        id: uuidv4(),
        task: userInput ? userInput.trim() : "",
        location: location ? location.trim() : "",
        resolution: resolution ? resolution.trim() : "",
        explanation: explanation ? explanation.trim() : "",
        topic: topic ? topic.trim() : "",
        complete: false,
        added_ts: (Date.now() / 1000) | 0,
      },
    ];
    setTasks(copy);
    setHasChanges(true);
  };

  const editTask = (
    id,
    userInput,
    location,
    resolution,
    explanation,
    topic
  ) => {
    let mapped = tasks.map((task) => {
      return task.id === id
        ? {
            ...task,
            task: userInput ? userInput.trim() : "",
            location: location ? location.trim() : "",
            resolution: resolution ? resolution.trim() : "",
            explanation: explanation ? explanation.trim() : "",
            topic: topic ? topic.trim() : "",
          }
        : { ...task };
    });
    setTasks(mapped);
    setHasChanges(true);
  };

  const addMeta = (k, v) => {
    setMetadata(
      [...metadata, { key: k, value: v }].sort((a, b) =>
        a.key > b.key ? 1 : -1
      )
    );
    setHasChanges(true);
  };

  const editMeta = (k, v) => {
    setMetadata(
      [
        ...metadata.filter((row) => row["key"] !== k),
        { key: k, value: v },
      ].sort((a, b) => (a.key > b.key ? 1 : -1))
    );

    setHasChanges(true);
  };

  const deleteMeta = (k) => {
    setMetadata(metadata.filter((row) => row["key"] !== k));
    setHasChanges(true);
  };

  // const handleDuplicate = () => {};

  const tasksToShow = tasks.sort((a, b) =>
    sortingOrderElement({ a, b, sortingOrder })
  );

  const initValues = {
    task: "",
    location: "",
    resolution: "",
  };

  if (sortingOrder != defaultSortingOrder) {
    localStorage.setItem("sortingOrder", sortingOrder);
  }

  return (
    <>
      {/* {hasChanges && (
        <BocomboSave id={id} data={tasks} setHasChanges={setHasChanges} />
      )} */}

      <BocomboSort
        sortingOrder={sortingOrder}
        setSortingOrder={setSortingOrder}
      />

      <BocomboMeta
        metadata={metadata}
        addMeta={addMeta}
        editMeta={editMeta}
        deleteMeta={deleteMeta}
      />

      <BocomboList
        list={tasksToShow}
        handleToggle={handleToggle}
        handleDelete={handleDelete}
        handleFilter={handleFilter}
        editTask={editTask}
      />

      {hasChanges && (
        <BocomboSave
          id={id}
          tasks={tasks}
          metadata={metadata}
          setHasChanges={setHasChanges}
        />
      )}
      <BocomboForm
        addTask={addTask}
        initValues={initValues}
        textAreaWidth="70%"
      />
    </>
  );
};

export default BocomboTodolist;
