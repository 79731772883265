import React, { useState } from "react";
import { saveList } from "./ApiFetch";
import BocomboSpinner from "./BocomboSpinner";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

const BocomboSave = ({ id, tasks, metadata, setHasChanges }) => {
  const [saving, setSaving] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmit = () => {
    setShowError(false);
    setSaving(true);
    saveList(id, tasks, metadata)
      .then((response) => {
        if (typeof response === "undefined") {
          setShowError(true);
        } else if (response.error) {
          setShowError(true);
        } else {
          setHasChanges(false);
        }
        setSaving(false);
      })
      .catch(() => {
        setShowError(true);
      });
  };

  if (saving) {
    return (
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <BocomboSpinner />
      </Box>
    );
  } else {
    return (
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        {showError && (
          <div>
            <Alert severity="error">Fehler beim Speichern</Alert>
          </div>
        )}
        <div>
          <Button variant="contained" onClick={handleSubmit}>
            Änderungen speichern
          </Button>
        </div>
      </Box>
    );
  }
};

export default BocomboSave;
